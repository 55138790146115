<template>
  <SmoothModal
    v-model="isModalOpen"
    :has-header="false"
    width="440px"
    @update:model-value="isModalOpen = false"
  >
    <StepsWrapper
      icon="shield-2fa"
      :steps="['code', 'qr', 'recovery']"
      :current-step="currentStep"
    >
      <template #title>
        {{ title }}
      </template>
      <template #description>
        {{ description }}
      </template>

      <EnableTOTP
        v-if="currentStep === 'code'"
        v-model="link"
        :fn="totpFunction"
        :mask="mask"
        @next="onNext('qr')"
        @close="toggleModal"
      />
      <QrCode
        v-if="currentStep === 'qr'"
        v-model:codes="recoveryCodes"
        :link="link"
        :fn="qrFunction"
        @next="onNext('recovery')"
        @close="toggleModal"
      />
      <RecoveryCodesOther
        v-if="currentStep === 'recovery'"
        :recovery-codes="recoveryCodes"
        @submit="toggleModal"
      />
    </StepsWrapper>
  </SmoothModal>
</template>

<script setup>
import {
  computed, ref, watch,
} from 'vue';

import RecoveryCodesOther from '@/components/Modules/RecoveryCodesOther.vue';
import SmoothModal from '@/components/SmoothModal';
import StepsWrapper from '@/components/Containers/StepsWrapper.vue';

import { i18n } from '@/plugins/localization';

import EnableTOTP from './EnableTOTP.vue';
import QrCode from './QrCode.vue';

defineProps({
  totpFunction: {
    type: Function,
    default: () => {},
  },
  qrFunction: {
    type: Function,
    default: () => {},
  },
  mask: {
    type: String,
    default: 'onlyInteger',
  },
});

const emit = defineEmits(['update']);

const { t } = i18n.global;
const isModalOpen = defineModel({ type: Boolean, default: false });
const currentStep = ref('code'); // code, qr, recovery

const onNext = (step) => {
  currentStep.value = step;
  emit('update');
};

const toggleModal = () => {
  isModalOpen.value = !isModalOpen.value;
};

watch(isModalOpen, (isOpen) => {
  if (!isOpen) {
    currentStep.value = 'code';
  }
});

const link = ref('');
const recoveryCodes = ref([]);

const title = computed(() => {
  if (currentStep.value === 'code') {
    return t('component.changeToken.title.changeToken');
  }

  if (currentStep.value === 'qr') {
    return t('component.changeToken.title.setupNewToken');
  }

  return t('component.changeToken.title.saveRecovery');
});

const description = computed(() => {
  if (currentStep.value === 'code') {
    return t('component.changeToken.description.changeToken');
  }

  if (currentStep.value === 'qr') {
    return t('component.changeToken.description.setupNewToken');
  }

  return t('component.changeToken.description.saveRecovery');
});
</script>
